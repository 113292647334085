export default {
	cinema: {
		address: 'ул. Московская, 54',
		place: null,
		phoneNumbers: [],
		facebook: null,
		instagram: null,
		vk: null,
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: null,
		selectButton: {
			options: [
				{
					value: '//mir.orenkino.ru',
					title: 'Мир, Орск',
				},
				{
					value: '//sokol.orenkino.ru',
					title: 'Сокол, Оренбург',
				},
				{
					value: '//junost.orenkino.ru',
					title: 'Юность, Абдулино',
				},
				{
					value: '//rodina.orenkino.ru',
					title: 'Родина, Бугуруслан',
				},
				{
					value: '//ural.orenkino.ru',
					title: 'Урал, Медногорск',
				},
				{
					value: '//kolos.orenkino.ru',
					title: 'Колос, Новосергиевка',
				},
			],
			selectedOptionValue: '//rodina.orenkino.ru',
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
